<template>
  <div class="settings__container">
    <!-- <div class="settings__header__wrapper">
      <h1 class="sr-only">{{ name }}</h1>
      <h1 class="settings__header">
        {{ name }}
      </h1>
    </div> -->
    <header class="flex justify-between -mt-2">
      <div class="w-full">
        <h1 class="sr-only">Settings</h1>
        <h1 class="text-2xl leading-7 tracking-tight font-semibold text-gray-800 sm:text-2xl sm:truncate">Settings</h1>
        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Adjust global settings including enabling and disabling protection organization-wide, user-initiated offline failsafe, send anyway timeout, and footer text.
        </p>
      </div>
    </header>

    <div class="settings__form__wrapper">
      <!-- Enable Preava Prevent -->
      <SwitchGroup>
        <div class="settings__switch__wrapper">
          <!--
            [NOTE]:
            The long list of classes found for Switch can't be moved to settings.css.
            It needs to be inlined otherwise it will cause a bug with the switch not
            properly working. Please keep the Switch classes here.
            - by @johnnypreavacom
          -->
          <Switch
            v-model="isPreventEnabled"
            :disabled="isWaiting"
            :class="[
              isPreventEnabled ? 'bg-blue-600' : 'bg-gray-300',
              'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
            ]"
          >
            <span :class="[isPreventEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
          </Switch>
          <SwitchLabel class="settings__label"> Scan outbound emails for risks </SwitchLabel>
        </div>
      </SwitchGroup>

      <!-- Authorization Domain -->

      <div>
        <div class="settings__section">
          <label for="authdomain" class="settings__section__header"> Authentication Domains </label>

          <DefaultTooltip message="All your company's authentication domains configured on the Preava IAM systems." />
        </div>

        <div v-if="tenant.domainsAuthenticaion">
          <div v-for="domain in tenant.domainsAuthenticaion.valuesList" :key="domain.value">
            <input
              v-model="domain.value"
              type="text"
              :name="`authdomain-${domain.value}`"
              :id="`authdomain-${domain.value}`"
              autocomplete="off"
              disabled
              aria-invalid="true"
              :aria-describedby="`authdomain-${domain.value}`"
              class="mt-2 relative shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            />
          </div>
        </div>
        <div v-else>
          <input
            type="text"
            name="authdomain"
            id="authdomain"
            autocomplete="off"
            disabled
            placeholder="loading..."
            aria-invalid="true"
            aria-describedby="authdomain"
            class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>
      </div>

      <!-- Internal Domain -->

      <div>
        <div class="settings__section">
          <label for="internaldomain" class="settings__section__header"> Internal Domains </label>

          <DefaultTooltip message="All your company's internal domains used for sending emails." />
        </div>

        <div v-if="tenant.domainsCommunication">
          <div v-for="domain in tenant.domainsCommunication.valuesList" :key="domain.value">
            <input
              v-model="domain.value"
              type="text"
              :name="`internaldomain-${domain.value}`"
              :id="`internaldomain-${domain.value}`"
              autocomplete="off"
              disabled
              aria-invalid="true"
              :aria-describedby="`internaldomain-${domain.value}`"
              class="mt-2 relative shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
            />
          </div>
        </div>
        <div v-else>
          <input
            type="text"
            name="internaldomain"
            id="internaldomain"
            autocomplete="off"
            disabled
            placeholder="loading..."
            aria-invalid="true"
            aria-describedby="internaldomain"
            class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-gray-100 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
          />
        </div>
      </div>

      <div class="settings__section--footer">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="isOfflineModeEnabled"
              :disabled="isWaiting"
              :class="[
                isOfflineModeEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[isOfflineModeEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label">Send unscanned emails on poor internet connections</SwitchLabel>
            <MultilineTooltip
              :messages="[
                'Preava Prevent endpoint protection software automatically monitors user’s internet connection quality. When the connection quality to Preava servers is poor, users can send unscanned emails without being notified. Once connection quality improves, Preava Prevent will automatically resume scanning emails for risks.',
                'Enable this setting when your organization needs to maintain the ability to send emails when users are on internet connections of poor quality (e.g. while working from remote areas).'
              ]"
            />
          </div>
        </SwitchGroup>

        <p class="text-sm font-normal text-gray-600 w-full my-2 mt-2">
          Warning: Disabling this setting will prevent users from sending any emails when their internet connection quality is poor.
        </p>
      </div>

      <!-- Send anyway -->
      <div class="settings__section--footer">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <Switch
              v-model="isSendAnywaysEnabled"
              :disabled="isWaiting"
              :class="[
                isSendAnywaysEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[isSendAnywaysEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label">Send unscanned emails after timeout</SwitchLabel>
            <MultilineTooltip
              :messages="[
                'Preava Prevent endpoint protection software automatically monitors the response time of our preflight API, which is responsible for scanning emails for risks at the endpoint. When the response time exceeds the set timeout period, users can send unscanned emails without being notified.',
                'Enable this setting when your organization needs to maintain the ability to send emails when temporary API or internet connection interruptions occur.'
              ]"
            />
          </div>
        </SwitchGroup>

        <div class="flex flex-col space-y-2 w-full mt-2">
          <label for="steps-range" class="settings__label--footer">
            Timeout period:
            <span v-if="sendAnywaysTimeoutMs !== undefined">{{ new Intl.NumberFormat("en-US").format(sendAnywaysTimeoutMs / 1000) }} seconds </span></label
          >
          <input
            type="range"
            min="1000"
            max="5000"
            step="250"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer disabled:cursor-default range-lg dark:bg-gray-700"
            :disabled="!isSendAnywaysEnabled || isWaiting"
            v-model="sendAnywaysTimeoutMs"
          />
          <div class="flex justify-between w-full px-[10px]">
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(1) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(2) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(3) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(4) }}s</span>
            <span class="text-sm font-normal text-gray-600">{{ new Intl.NumberFormat("en-US").format(5) }}s</span>
          </div>
        </div>
        <p class="text-sm font-normal text-gray-600 w-full my-2 mt-2">
          Warning: Disabling this setting will prevent users from sending any emails when Preava servers are unreachable.
        </p>
      </div>

      <!-- Preava Prevent Footer -->
      <div class="settings__section--footer">
        <SwitchGroup>
          <div class="settings__switch__wrapper">
            <!--
                [NOTE]:
                The long list of classes found for Switch can't be moved to settings.css.
                It needs to be inlined otherwise it will cause a bug with the switch not
                properly working. Please keep the Switch classes here.
                - by @johnnypreavacom
              -->
            <Switch
              v-model="isFooterEnabled"
              :disabled="isWaiting"
              :class="[
                isFooterEnabled ? 'bg-blue-600' : 'bg-gray-300',
                'relative inline-flex items-center h-6 transition-colors rounded-full w-11 min-w-switch focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:cursor-auto',
              ]"
            >
              <span :class="[isFooterEnabled ? 'translate-x-6' : 'translate-x-1', 'inline-block w-4 h-4 transition-transform transform bg-white rounded-full']" />
            </Switch>
            <SwitchLabel class="settings__label"> Append email footer text </SwitchLabel>
            <DefaultTooltip message="Add custom email footer text to all emails scanned by Preava Prevent." />
          </div>
        </SwitchGroup>

        <!-- Footer Text -->
        <label for="footer-text" class="settings__label--footer"> Email footer text </label>
        <input
          v-if="settings.footerContent"
          type="text"
          name="footer-text"
          id="footer-text"
          :disabled="!isFooterEnabled || isWaiting"
          v-model="footerContent"
          autocomplete="off"
          class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 text-gray-500 placeholder-gray-300 bg-white focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer"
        />
        <input
          v-else
          type="text"
          name="footer-text"
          id="footer-text"
          disabled
          value="Please wait..."
          autocomplete="off"
          class="mt-1 relative rounded-md shadow-sm block w-full pr-10 border-gray-300 placeholder-gray-300 bg-gray-100 focus:outline-none focus:border-gray-500 focus:ring-gray-500 sm:text-sm rounded-md-footer text-gray-400"
        />
        <p class="text-sm font-normal text-gray-600 w-full my-2">
          Note: Due to a Microsoft limitation, the email footer text may not be appended to emails sent from Outlook for Mac.
        </p>
      </div>
    </div>

    <button :disabled="isWaiting || (isSendAnywaysEnabled && isInvalidTimeout)" @click="setSettings()" class="settings__button">
      {{ !isWaiting ? "Save" : "Please wait..." }}
    </button>

    <ToastNotification :toast="toast" />

    <DefaultNotification :notification="notification" />
  </div>
</template>
<script>
// Components
import ToastNotification from "@/components/notification/ToastNotification";
import DefaultNotification from "@/components/notification/DefaultNotification";
import DefaultHeader from "@/components/header/DefaultHeader";
import DefaultSidebar from "@/components/sidebar/DefaultSidebar";
import DefaultTooltip from "@/components/tooltip/DefaultTooltip";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

// Adapter and User classes, etc.
import { Adapter } from "@/utilities/adapter";
import enums from "@preava/preava-prevent-api-grpc-web-js/enums_pb";
import { grpcErrorToString } from "@/utilities/helpers";
import { v4 } from "uuid";
import MultilineTooltip from "../../components/tooltip/MultilineTooltip.vue";

const ADAPTER = new Adapter();
const ENUMS = enums;
const NAME = "Settings";

export default {
  name: NAME,
  site_title: NAME,
  components: {
    // Components
    DefaultHeader,
    DefaultSidebar,
    ToastNotification,
    DefaultNotification,
    DefaultTooltip,
    Switch,
    SwitchGroup,
    SwitchLabel,
    ExclamationCircleIcon,
    MultilineTooltip,
  },
  data() {
    return {
      name: NAME,
      // UI Settings
      isAccessible: false, // for admin check
      isWaiting: false,
      isPreventEnabled: false,
      isFooterEnabled: false,
      footerContent: "",
      isOfflineModeEnabled: false,
      isSendAnywaysEnabled: false,
      sendAnywaysTimeoutMs: 5000,
      isInvalidTimeout: false,
      traceId: null,
      // settings object
      settings: {},
      // tenant object
      tenant: {},
      // modified settings object
      modified_settings: {},
      // notification object
      notification: {
        isShown: false,
        title: "",
        details: "",
      },
      // toast object
      toast: {
        isShown: false,
        message: "",
      },
    };
  },
  watch: {
    isPreventEnabled(newVal, oldVal) {
      if (oldVal != newVal && !this.isWaiting) {
        this.updateModifiedSettingsData();
      }
    },
    isFooterEnabled(newVal, oldVal) {
      if (oldVal != newVal && !this.isWaiting) {
        this.updateModifiedSettingsData();
      }
    },
    footerContent() {
      this.updateModifiedSettingsData();
    },
    isOfflineModeEnabled(newVal, oldVal) {
      if (oldVal != newVal && !this.isWaiting) {
        this.updateModifiedSettingsData();
      }
    },
    isSendAnywaysEnabled(newVal, oldVal) {
      if (oldVal != newVal && !this.isWaiting) {
        this.updateModifiedSettingsData();
      }
    },
    sendAnywaysTimeoutMs() {
      this.updateModifiedSettingsData();
    },
  },

  methods: {
    // 🎨 UI
    // these are only for UI. We can't v-model the Switch buttons with the ENUMS since it requires Boolen
    // this way, we also isolate the backend settings object returned
    updateUiSettingsData() {
      this.isPreventEnabled = this.settings.protectionLevel === ENUMS.Protection.PROTECTION_PREFLIGHT ? true : false;
      this.isFooterEnabled = this.settings.footerStatus === ENUMS.Status.STATUS_ENABLED ? true : false;
      this.footerContent = this.settings.footerContent.value;
      this.isOfflineModeEnabled = this.settings.offlineModeStatus === ENUMS.Status.STATUS_ENABLED ? true : false;
      this.isSendAnywaysEnabled = this.settings.sendAnywaysStatus === ENUMS.Status.STATUS_ENABLED ? true : false;
      this.sendAnywaysTimeoutMs = this.settings.sendAnywaysTimeoutMs;
    },

    // 🎨 UI
    // the modified_settings object is the modified settings that the user sets
    // this modified_settings will be passed to the backend later when "saved"
    // if error occurs, then the UI will reflect the original settings object before it was modified
    // and will reset the modified_settings
    updateModifiedSettingsData() {
      console.log("--- Settings Object Changes: ---");
      console.log("original_settings", this.settings);
      this.modified_settings.protectionLevel = this.isPreventEnabled ? ENUMS.Protection.PROTECTION_PREFLIGHT : ENUMS.Protection.PROTECTION_DISABLED;
      this.modified_settings.footerStatus = this.isFooterEnabled ? ENUMS.Status.STATUS_ENABLED : ENUMS.Status.STATUS_DISABLED;
      this.modified_settings.footerContent = {};
      this.modified_settings.footerContent.value = this.footerContent;
      this.modified_settings.offlineModeStatus = this.isOfflineModeEnabled ? ENUMS.Status.STATUS_ENABLED : ENUMS.Status.STATUS_DISABLED;
      this.modified_settings.sendAnywaysStatus = this.isSendAnywaysEnabled ? ENUMS.Status.STATUS_ENABLED : ENUMS.Status.STATUS_DISABLED;
      this.modified_settings.sendAnywaysTimeoutMs = parseInt(this.sendAnywaysTimeoutMs);
      console.log("modified_settings", this.modified_settings);
    },

    // 📡 COMMS
    // Fetch the settings object from the backend
    async getSettings() {
      return new Promise(async (resolve, reject) => {
        this.isWaiting = true;

        this.toast = {
          isShown: true,
          message: "Fetching settings...",
        };

        ADAPTER.getSettings(this.traceId)
          .then((settings_obj) => {
            this.settings = settings_obj;
            this.updateUiSettingsData();
          })
          .catch((err) => {
            console.log(err);
            // show error notification
            this.notification = {
              type: "error",
              title: "An Error Occurred",
              details: `${err.message}. [${grpcErrorToString(err.code)}].`,
              isShown: true,
            };
            reject();
          })
          .finally(() => {
            this.isWaiting = false;
            this.toast.isShown = false;
            resolve();
          });
      });
    },

    // 📡 COMMS
    // Update the settings object in the backend
    async setSettings() {
      this.isWaiting = true;

      this.toast = {
        isShown: true,
        toastmessage: "Updating settings...",
      };

      ADAPTER.setSettings(this.traceId, this.modified_settings)
        .then((settings_obj) => {
          this.settings = settings_obj;
          this.notification = {
            type: "success",
            title: "Success!",
            details: `Settings has been successfully updated.`,
            isShown: true,
          };
        })
        .catch((err) => {
          // show error notification
          this.notification = {
            type: "error",
            title: "An Error Occurred",
            details: `${err.message}. [${grpcErrorToString(err.code)}].`,
            isShown: true,
          };
        })
        .finally(() => {
          this.updateUiSettingsData(); // success or fail, always show the latest settings values in the checkbox ui
          this.isWaiting = false;
          this.toast.isShown = false;
        });
    },

    // 📡 COMMS
    // Fetch the tenant object from the backend
    async getTenant() {
      return new Promise(async (resolve, reject) => {
        this.isWaiting = true;

        this.toast = {
          isShown: true,
          message: "Fetching tenant...",
        };

        ADAPTER.getTenant(this.traceId)
          .then((tenant_obj) => {
            console.log("💢");
            console.log(tenant_obj);
            this.tenant = tenant_obj;
          })
          .catch((err) => {
            // show error notification
            this.notification = {
              type: "error",
              title: "An Error Occurred",
              details: `${err.message}. [${grpcErrorToString(err.code)}].`,
              isShown: true,
            };
            reject();
          })
          .finally(() => {
            this.isWaiting = false;
            this.toast.isShown = false;
            resolve();
          });
      });
    },

    /**
     * ✅ This method will do an admin check before the user can access the page.
     * 📡 Will communicate with backend using adapter.
     */
    async adminGuard() {
      return new Promise(async (resolve, reject) => {
        ADAPTER.activate(this.traceId).then(async (result) => {
          this.buttonText = result.bottle.message.value;
          if (result.role === ENUMS.Role.ROLE_ADMIN) {
            // If admin, proceed normally
            console.log("%cYou have the right to view this page.", "color: #B4D455");
            this.isAccessible = true;
            resolve();
          } else if (result.role === ENUMS.Role.ROLE_USER) {
            // Else if user, redirect immediately to /endpoints page
            console.log("%cYou don't have the right to view this page. Redirecting...", "color: #FE2020");
            this.$router.push({ path: "/integrations" });
          }
        });
      });
    },
  },

  async created() {
    /**
     * Each Activate, we have to set the trace ID per page.
     * This will be referenced later by other requests.
     */
    this.traceId = v4();

    await this.adminGuard();
    await this.getTenant();
    await this.getSettings();
    console.log("Settings Page Initialized");
  },
};
</script>
<style scoped>
@import "./settings.css";
</style>
